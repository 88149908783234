import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { Auth0Provider } from "@auth0/auth0-react";

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <Auth0Provider
    domain="login.onremac.com"
    clientId="uTS2KNWQTDkTQirFEp9zK4zuUBwZvjzT"
    redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
    
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
