import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, Link, Route, Routes } from 'react-router-dom'
import { CAlert, CAlertHeading, CButton, CCard, CCardBody, CCardGroup, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CImage, CInputGroup, CInputGroupText, CRow, CSpinner } from '@coreui/react-pro'
import { useAuth0 } from '@auth0/auth0-react';
import './scss/style.scss'
import { useDispatch } from 'react-redux';
import AppLogin from './components/AppLogin';
import { cilLockLocked, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import jwt_decode from "jwt-decode";

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))


const App = () => {

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [appMetadata, setAppMetadata] = useState(null);
  const dispatch = useDispatch()
  const [userProfile, setUserProfile] = useState(null)
  const namespace = 'https://login.onremac.com/'
  //console.log('user: ',user)
  
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = 'api.onremac.com';
  
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/v3/accounts/`,
          
        });
        const decoded = jwt_decode(accessToken)
        //console.log('token: ',decoded)
        //console.log('user: ',user)
        const url = `https://api.onremac.com/v3/users/${encodeURIComponent(user.sub)}`;
            const resp = await fetch(url,{
                headers: {
                  'Content-Type' : 'application/json',
                  Authorization: `Bearer ${accessToken}`
                  }
            })
        const u = await resp.json();
        //const { user_metadata, app_metadata } = await metadataResponse.json();
        //console.log('user_metadata: ',user_metadata)
        //console.log('app_metadata: ',app_metadata)
        //console.log('userProfile: ', u)
        setUserProfile(u);
        dispatch({type: 'set', userProfile: u})
        //setUserMetadata(user_metadata);
        //dispatch({type: 'set', userMetadata: user_metadata})
        //dispatch({type: 'set', appMetadata: app_metadata})
        //setAppMetadata(app_metadata)
        
      } catch (e) {
        console.log(e.message);
      }
    };
    if(user)
    getUserMetadata();
  }, [getAccessTokenSilently, user]);

  if (isLoading ) {
    return <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
            <CRow className='justify-content-center' >
            <CCol xs={12} className='d-block text-center'>
              <CImage src='loading7.gif' height={200} />
            </CCol>
            </CRow>
      </CContainer>

    </div>
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  
  if (isAuthenticated) {
    if(!userProfile){
      return <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
            <CRow className='justify-content-center' >
            <CCol xs={12} className='d-block text-center'>
              <CImage src='loading7.gif' height={200} />
            </CCol>
            </CRow>
      </CContainer>

    </div>
    }
    if(!userProfile.auth){
      return (
        <CAlert color='info'>
          <CAlertHeading>Account not activated</CAlertHeading>
          Your account has not been activated yet. Please contact our support or try again later. <br/>
          <CButton size="sm" color="secondary" onClick={() => logout({ returnTo: window.location.origin })}>Logout</CButton>
        </CAlert>
      )
    }
    if(!userProfile.auth.permissions.includes('master:admin') && !userProfile.auth.permissions.includes('read:current_account')){
      return (
        <CAlert color='info'>
          <CAlertHeading>Access denied</CAlertHeading>
          You dont have access to this application <br/>
          <CButton size="sm" color="secondary" onClick={() => logout({ returnTo: window.location.origin })}>Logout</CButton>
        </CAlert>
      )
    } 
    if(user.tenantId){
      return (
        <CAlert color='info'>
          <CAlertHeading>Account not activated</CAlertHeading>
          Your account has not been activated yet. Please contact our support or try again later. <br/>
          <CButton size="sm" color="secondary" onClick={() => logout({ returnTo: window.location.origin })}>Logout</CButton>
        </CAlert>
      )
    }
    else 
  return (
    <HashRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout app_metadata={appMetadata} />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
  } else {
    
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <AppLogin />
          </CContainer>
        </div>
    )
    /*
    return (
      <CButton onClick={() => loginWithRedirect()}>Login</CButton>
    )
    */
    
  }

  
}

export default App
